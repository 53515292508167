<template>
  <v-card :loading="isLoading">
    <v-card-text>
      <v-data-table
        :fixed-header="false"
        :height="undefined"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Toplu Yaşam Alanı Tanımları"
            icon="mdi-home-city-outline"
            :add-route="
              can('add-cluster')
                ? { name: 'definitions.clusters.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            @click:edit="handleEditClick"
            :hide-edit="!can('edit-cluster')"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            not-price
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'definitions.clusters.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item, value }">
          <router-link
            :to="{
              name: 'definitions.clusters.show',
              params: { id: item.id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.company="{ item, value }">
          <router-link
            v-if="value"
            :to="{
              name: 'definitions.companies.show',
              params: { id: item.company_id },
            }"
          >
            {{ value }}
          </router-link>
          <template v-else><em>Yok</em></template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.customer_name="{ item, value }">
          <router-link
            v-if="value"
            :to="{
              name: 'admin.crm.customers.edit',
              params: { id: item.customer_id },
            }"
          >
            {{ value }}
          </router-link>
          <template v-else><em>Yok</em></template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.has_bank_integration="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.has_credit_card_payments="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.sms_integration="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_closed="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_demo="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.agreement_renewal_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.invoicing_begins_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.should_delete_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.suspends_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions, isPage } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions, isPage],
  mounted() {
    this.generateExportColumns(this.headers);
  },
  data() {
    return {
      pageMeta: {
        title: "Toplu Yaşam Alanı Tanımları",
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-cluster"),
        },
        {
          text: this.$t("labels.cluster_name"),
          value: "name",
          searchable: "text",
          sortable: true,
        },
        {
          text: "Durum",
          value: "is_active",
          searchable: "isActive",
          sortable: true,
          align: "center",
        },
        {
          text: "Kapalı",
          value: "is_closed",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: "Demo",
          value: "is_demo",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: "Banka Entegrasyonu",
          value: "has_bank_integration",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: "SMS Entegrasyonu",
          value: "sms_integration",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: "Kredi Kartı Entegrasyonu",
          value: "has_credit_card_payments",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("labels.authorized_person"),
          value: "authorized_person",
          searchable: "text",
          sortable: true,
        },
        {
          text: this.$t("labels.house_count"),
          value: "house_count",
          searchable: "number",
          sortable: true,
          align: "end",
        },
        {
          text: this.$t("labels.management_company"),
          value: "company",
          searchable: "text",
          sortable: true,
        },
        {
          text: "Müşteri",
          value: "customer_name",
          searchable: "text",
          hide: () => !this.hasRole("superadministrator"),
        },
        {
          text: this.$t("labels.new_aggrement_date"),
          value: "agreement_renewal_on",
          searchable: "date",
          hide: () => !this.hasRole("superadministrator"),
        },
        {
          text: "Fatura Başlangıç Tarihi",
          value: "invoicing_begins_on",
          searchable: "date",
          hide: () => !this.hasRole("superadministrator"),
        },
        {
          text: "Silinmesi Gereken Tarih",
          value: "should_delete_on",
          searchable: "date",
          hide: () => !this.hasRole("superadministrator"),
        },
        {
          text: "Hizmet durdurma tarihi",
          value: "suspends_on",
          searchable: "date",
          hide: () => !this.hasRole("superadministrator"),
        },
        {
          text: "VKN",
          value: "tax_number",
          searchable: "string",
          hide: () => !this.hasRole("superadministrator"),
        },
      ],
      titleBarAttrs: {
        exportUrl: () => "clusters",
        exportParams: this.getParams,
      },
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return params;
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.resetTable();
      const params = this.getParams();
      this.isLoading = true;

      this.$api
        .query("clusters", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "definitions.clusters.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
  },
};
</script>
